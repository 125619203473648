import { template as template_66a451b2f26b40aeb5a7bbd4e2e1585b } from "@ember/template-compiler";
const WelcomeHeader = template_66a451b2f26b40aeb5a7bbd4e2e1585b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
